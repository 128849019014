import React from "react";
import { Link } from "gatsby";
import { v4 as uuid } from "uuid";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/es";
dayjs.extend(relativeTime);

const RenderPostAlt = ({ data, styleClass = "default" }) => {
    if (!data) return null;

    // Datos del post
    const slug = data.attributes.slug;
    const title = data.attributes.title;
    const description = data.attributes.description;
    const publishedAt = data.attributes.publishedAt;

    // Datos de cover
    const cover = data?.attributes?.cover;
    const coverUrl = cover?.data?.attributes?.url;
    const coverPreview =
        cover?.data?.attributes?.formats?.medium?.url ||
        cover?.data?.attributes?.formats?.large?.url;

    // Datos del author
    const author = data?.attributes?.author;
    const authorSlug = author?.data?.attributes?.slug;
    const authorName = author?.data?.attributes?.name;
    const authorPosition = author?.data?.attributes?.position;
    const authorAvatar =
        author?.data?.attributes?.avatar?.data?.attributes?.formats?.thumbnail?.url ||
        author?.data?.attributes?.avatar?.data?.attributes?.url;

    // Categorias
    const categories = data?.attributes?.categories;

    const styles = stylesAlt[styleClass];

    // Calculamos tiempos
    const currentDate = dayjs();

    // Calcula la diferencia en días entre la fecha actual y la fecha de publicación
    const daysDifference = currentDate.diff(dayjs(publishedAt), "hour") / 24.0;

    return (
        <article className={styles.article}>
            {/** Cover */}
            {coverUrl && (
                <Link to={`/blog/${slug}`} title={title} className={styles.coverLink}>
                    <img src={coverPreview || coverUrl} alt={title} className={styles.cover} />
                </Link>
            )}

            <div>
                {/** Categorías y fecha */}
                <div className={styles.categoriesAndDateContainer}>
                    {/** Categorías */}
                    {categories?.data?.map((category, index) => {
                        const slug = category.attributes.slug;
                        const name = category.attributes.name;
                        const isLast = index === categories.data.length - 1;

                        return (
                            <React.Fragment key={uuid()}>
                                <Link
                                    to={`/blog/categoria/${slug}`}
                                    title={name}
                                    className={styles.categoryLink}
                                >
                                    <span className={styles.category}>{name}</span>
                                </Link>
                                {!isLast && <span className="text-gray-500">|</span>}
                            </React.Fragment>
                        );
                    })}

                    {/** Separador */}
                    <span>-</span>

                    {/** Fecha de publicación */}
                    <time
                        dateTime={dayjs(publishedAt).format("YYYY-MM-DD HH:mm:ss")}
                        className={styles.date}
                    >
                        {daysDifference > 1
                            ? dayjs(publishedAt).format("DD MMMM YYYY HH:mm")
                            : dayjs(publishedAt).fromNow(false)}
                    </time>
                </div>

                {/** Título */}
                <Link to={`/blog/${slug}`} title={title} className={styles.titleLink}>
                    <h1 className={styles.title}>{title}</h1>
                </Link>

                {/** Descripción */}
                <h2 className={styles.description}>{description}</h2>

                {/** Autor */}
                <div className={styles.authorContainer}>
                    {authorAvatar && (
                        <img
                            src={authorAvatar}
                            width={100}
                            height={100}
                            className={styles.authorAvatar}
                            alt={authorName}
                        />
                    )}

                    <div>
                        <Link className={styles.authorLink} to={`/blog/author/${authorSlug}`}>
                            <h1 className={styles.authorName}>{authorName}</h1>
                        </Link>
                        <h2 className={styles.authorPosition}>{authorPosition}</h2>
                    </div>
                </div>
            </div>
        </article>
    );
};

const stylesAlt = {
    default: {
        article: "flex flex-col gap-5",
        coverLink: "duration-300 hover:opacity-75 flex-shrink-0",
        cover: "rounded object-cover h-72 w-full",
        title: "font-semibold text-lg mt-2 truncate-2-lines",
        titleLink: "duration-300 hover:text-cpurple text-gray-600",
        description: "text-gray-600 text-sm mt-2 truncate-3-lines",
        date: "text-gray-600 text-sm",
        categoriesAndDateContainer: "space-x-2 truncate-3-lines",
        category: "text-sm",
        categoryLink: "font-semibold text-gray-700 hover:text-ccyan duration-300",
        authorContainer: "mt-2 flex flex-row gap-5",
        authorAvatar: "rounded-full object-cover w-12 h-12 bg-gray-300",
        authorName: "font-bold text-sm",
        authorPosition: "text-gray-600 text-xs",
        authorLink: "hover:text-cindigo text-gray-700",
    },
    featured: {
        article: "flex flex-col lg:flex-row gap-10 w-full md:col-span-2 lg:col-span-3",
        coverLink: "duration-300 hover:opacity-75 flex-shrink-0",
        cover: "rounded object-cover h-88 lg:h-72 md:w-88 lg:w-116 w-full",
        title: "font-semibold text-lg mt-2 truncate-2-lines",
        titleLink: "duration-300 hover:text-cpurple text-gray-600",
        description: "text-gray-600 text-sm mt-2 truncate-2-lines",
        date: "text-gray-600 text-sm",
        categoriesAndDateContainer: "space-x-2 truncate-3-lines",
        category: "text-sm",
        categoryLink: "font-semibold text-gray-700 hover:text-ccyan duration-300",
        authorContainer: "mt-2 flex flex-row gap-5",
        authorAvatar: "rounded-full object-cover w-12 h-12 bg-gray-300",
        authorName: "font-bold text-sm",
        authorPosition: "text-gray-600 text-xs",
        authorLink: "hover:text-cindigo text-gray-700",
    },
    preview: {
        article: "flex flex-col lg:flex-row gap-10 w-full",
        cover: "rounded object-cover lg:h-48 h-58 lg:w-72 w-full",
        coverLink: "duration-300 hover:opacity-75 flex-shrink-0",
        title: "font-semibold text-lg mt-2 truncate-2-lines",
        titleLink: "duration-300 hover:text-cpurple text-gray-600",
        description: "text-gray-600 text-sm mt-2 truncate-2-lines",
        date: "text-gray-600 text-sm",
        categoriesAndDateContainer: "space-x-2 truncate-3-lines",
        category: "text-sm",
        categoryLink: "font-semibold text-gray-700 hover:text-ccyan duration-300",
        authorContainer: "mt-2 flex flex-row gap-5",
        authorLink: "hover:text-cindigo text-gray-700",
        authorAvatar: "rounded-full object-cover w-12 h-12 bg-gray-300",
        authorName: "font-bold text-sm",
        authorPosition: "text-gray-600 text-xs",
    },
};

// export default RenderPost;
export { RenderPostAlt };
